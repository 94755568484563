import clsx from "clsx";
import { format } from "date-fns";
import { useState } from "react";
// import SwipeToDelete from "react-swipe-to-delete-ios";
import { type Link as LinkType } from "../../contracts";
import {
  useAppContext,
  useFlashChanges,
  useLinks,
  useSneakySettings,
} from "../../hooks";
import { captureError } from "../../utils";
import { TimeAgo } from "../TimeAgo";
import { LinkActions } from "./LinkActions";
import { LinkFlagOverline } from "./LinkFlagOverline";
import { LinkType as LinkTypeComponent } from "./LinkType";
import { Sublinks } from "./Sublinks";
import classes from "./Link.module.scss";
import { Badge } from "react-bootstrap";

export interface LinkProps extends Pick<LinkType, "id"> {
  /**
   * Additional classes to be applied to the link wrapper.
   */
  className?: string;

  /**
   * Tapping and holding this link should show the settings screen.
   */
  sneakySettingsLink?: boolean;

  /**
   * Number the link in the list.
   */
  number?: number;

  /**
   * Is this the first link in the list? Pseudo-css selectors are not reliable in
   * some lists because of wrapping/nesting.
   */
  firstInList?: boolean;

  /**
   * Is this the last link in the list? Pseudo-css selectors are not reliable in
   * some lists because of wrapping/nesting.
   */
  lastInList?: boolean;
}

/**
 * Display a link in different ways depending on if in editor mode or not.
 *
 * In editor mode links can be edited and deleted.
 */
export const Link = ({
  className,
  id,
  sneakySettingsLink,
  number,
  firstInList,
  lastInList,
}: LinkProps) => {
  const { links, editorMode } = useAppContext();
  const { getLink, saveLink } = useLinks();
  const { linkHasChange } = useFlashChanges(id);
  const bindSneakySettings = useSneakySettings();
  const [showActions, setShowActions] = useState(false);

  const {
    date,
    flag,
    from,
    read,
    title,
    type,
    url,
    archived,
    archivedBy,
    archivedAt,
  } = getLink(id);
  const sublinks = links.filter((l) => l.parentId === id);

  const handleClickLink = async () => {
    // Tracking "analytics", don't prevent link from being followed.

    // The viewer can change the "read" status of unread links.
    if (!editorMode && !read) {
      try {
        await saveLink({ id, read: true });
      } catch (error) {
        /* Report the error, but don't consider this operation failing to be fatal.
        The read state is useful for the reader, but not essential for the viewer. */
        captureError(error);
      }
    }
  };

  const formattedDate = editorMode ? (
    // Long date format for editor mode for precision.
    format(date, "MMMM d, yyyy")
  ) : (
    <TimeAgo date={date} />
  );

  return (
    <div
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      // For easier debugging.
      data-link-id={id}
      className={clsx(
        classes.link,
        "list-group-item list-group-item-action",
        linkHasChange && classes["link--flash"],
        firstInList && classes.first,
        lastInList && classes.last,
        className
      )}
    >
      {number ? <div className={classes["link-counter"]}>{number}.</div> : null}

      <div className={classes.details}>
        {(flag || url === undefined || url === null) && (
          <div className="mb-2 d-flex flex-wrap gap-2">
            {flag && <LinkFlagOverline flag={flag} />}
            {(url === undefined || url === null) && (
              <Badge bg="secondary" style={{ opacity: 0.7 }}>
                Text Message
              </Badge>
            )}
          </div>
        )}

        <div
          className={clsx(
            classes["title-container"],
            !editorMode && classes["title-container--viewer"]
          )}
        >
          {url !== undefined && url !== null ? (
            <a
              href={url}
              onClick={handleClickLink}
              {...(sneakySettingsLink ? bindSneakySettings() : undefined)}
              className={clsx("fs-4", classes.title, !read && "fw-semibold")}
            >
              {title}
            </a>
          ) : (
            <span
              className={clsx(
                "fs-4",
                classes.title,
                !read && "fw-semibold",
                classes["message-title"]
              )}
            >
              {title}
            </span>
          )}

          {editorMode ? (
            <div
              className={clsx(
                classes["link-actions"],
                !showActions && "invisible"
              )}
            >
              <LinkActions id={id} archived={archived} />
            </div>
          ) : null}
        </div>

        {sublinks?.length ? (
          <div className={classes.sublinks}>
            <Sublinks
              links={sublinks}
              showActions={showActions}
              flag={flag || undefined}
            />
          </div>
        ) : null}

        <div className="d-flex align-items-center gap-2 flex-wrap">
          <LinkTypeComponent type={type} />
          <span className="text-nowrap">{formattedDate}</span>
          {from ? (
            <>
              {/* <span /> markup for flex child spacing.  */}
              <span>⸱</span> <span>{from}</span>
            </>
          ) : null}
        </div>

        {archived && archivedAt ? (
          <div className="mt-2">
            <small>
              Archived {archivedBy ? `by ${archivedBy}` : null} on{" "}
              {format(archivedAt, "MMMM d, yyyy")}
            </small>
          </div>
        ) : null}
      </div>
    </div>
  );
};
