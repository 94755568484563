import { format, parse } from "date-fns";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation } from "wouter";
import { FlagType, LinkType } from "../contracts";
import {
  SaveLinkType,
  useAppContext,
  useLinkModalContext,
  useLinks,
} from "../hooks";
import clsx from "clsx";

interface LinkFormProps {
  /**
   * Show all form fields. Sub-links may only want to show fields that will actually
   * display in the UI.
   *
   * @default true
   */
  showAllFields: boolean;
}

interface FormState {
  date: string;
  flag: FlagType | undefined;
  from: string;

  /**
   * Unused in the form UI, it's now set by dragging the links. Still here to fulfil
   * the contract.
   */
  order: number;

  title: string;

  /**
   * Type of the link.
   *
   * Message type is for entries without URLs.
   */
  type: LinkType;

  /**
   * URL for the link.
   */
  url: string;

  /**
   * Has this link been read by the viewer?
   */
  read: boolean;

  /**
   * Is this link archived?
   */
  archived: boolean;
}

/**
 * Form for creating new links and editing existing links.
 */
export const LinkForm = ({ showAllFields = true }: LinkFormProps) => {
  const [_location, setLocation] = useLocation();
  const { linkManagementState, updateAppState } = useAppContext();
  const { getLink, saveLink } = useLinks();
  const { showBoundary } = useErrorBoundary();
  const { setDirty, setSubmitting } = useLinkModalContext();
  const [formState, setFormState] = useState<FormState>({
    date: formatDate(new Date()),
    flag: undefined,
    from: "",
    order: 0,
    title: "",
    type: LinkType.Other,
    url: "",
    read: false,
    archived: false,
  });
  const urlInputRef = useRef<HTMLInputElement>(null);

  // When editing a link, initially populate the form with that link's data.
  useEffect(() => {
    if (!linkManagementState?.id) {
      return;
    }

    const link = getLink(linkManagementState?.id);

    setFormState({
      date: formatDate(link.date),
      flag: link.flag || undefined,
      from: link.from ?? "",
      order: link.order,
      title: link.title,
      type: link.type || LinkType.Other,
      url: link.url ?? "",
      read: link.read ?? false,
      archived: link.archived,
    });
  }, [linkManagementState?.id, getLink]);

  // Focus the first field when showing the form.
  useEffect(() => {
    if (!urlInputRef.current) {
      throw Error("urlInputRef is not assigned.");
    }

    if (!linkManagementState?.id) {
      urlInputRef.current.focus();
    }
  }, [linkManagementState?.id]);

  const save = async () => {
    try {
      setSubmitting(true);

      const link: SaveLinkType = {
        ...(linkManagementState?.id ? { id: linkManagementState?.id } : {}),
        ...(linkManagementState?.parentId
          ? { parentId: linkManagementState.parentId }
          : {}),
        date: parseDate(formState.date),
        flag: formState.flag ?? null,
        from: formState.from,
        order: formState.order,
        title: formState.title,
        type: formState.type || LinkType.Other,
        url: formState.url ? formState.url : null,
        read: formState.read,
        archived: formState.archived,
      };

      await saveLink(link);
      close();

      // If adding a new link, reset to the live view to see it.
      if (!linkManagementState?.id) {
        setLocation("/");
      }
    } catch (error) {
      showBoundary(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFieldChange = (
    key: keyof FormState,
    value: FlagType | string | boolean | undefined
  ) => {
    const newFormState = {
      ...formState,
      [key]: value,
    };

    setFormState(newFormState);
    setDirty(true);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    save();
  };

  const close = () => {
    updateAppState({ linkManagementState: undefined });
    setDirty(false);
  };

  return (
    <Form id="link-form" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="url">URL</Form.Label>
        <Form.Control
          id="url"
          onChange={(event) => handleFieldChange("url", event.target.value)}
          ref={urlInputRef}
          type="url"
          value={formState.url}
        />
        <Form.Text className="text-muted">
          Empty URL creates a text-only message with no clickable link.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor="title">Title*</Form.Label>
        <Form.Control
          id="title"
          onChange={(event) => handleFieldChange("title", event.target.value)}
          required
          type="text"
          value={formState.title}
        />
      </Form.Group>

      <div className={clsx(!showAllFields && "d-none")}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="date">Date*</Form.Label>
          <Form.Control
            id="date"
            onChange={(event) => handleFieldChange("date", event.target.value)}
            required
            type="date"
            value={formState.date}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="from">From</Form.Label>
          <Form.Control
            id="from"
            onChange={(event) => handleFieldChange("from", event.target.value)}
            type="text"
            value={formState.from}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="flag">Flag</Form.Label>
          <Form.Select
            id="flag"
            onChange={(event) => handleFieldChange("flag", event.target.value)}
            value={formState.flag}
          >
            <option value="">Add a flag</option>
            <option value={FlagType.ForFeedback}>For feedback</option>
            <option value={FlagType.Important}>Important</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            onChange={(event) =>
              handleFieldChange(
                "type",
                event.target.checked ? LinkType.Roku : LinkType.Other
              )
            }
            checked={formState.type === LinkType.Roku}
            id="type"
            label="On Roku"
            type="checkbox"
          />
        </Form.Group>
      </div>

      <Form.Group>
        <Form.Check
          onChange={(event) => handleFieldChange("read", event.target.checked)}
          checked={formState.read}
          id="read"
          label="Read"
          type="checkbox"
        />
      </Form.Group>
    </Form>
  );
};

function formatDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

function parseDate(date: string) {
  return parse(date, "yyyy-MM-dd", new Date());
}
