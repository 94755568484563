export enum LinkType {
  Article = "article",
  Other = "other",
  Report = "report",

  /**
   * Link is viewable on Roku device.
   */
  Roku = "roku",
  Video = "video",
}

export enum FlagType {
  /**
   * Link requires feedback, but isn't critical.
   */
  ForFeedback = "for-feedback",

  /**
   * Link is critical or time sensitive.
   */
  Important = "important",
}

export interface Link {
  /**
   * If a sub-link, it'll have a parent ID.
   */
  parentId?: string;

  /**
   * When the Link was created.
   */
  createdAt: Date;

  /**
   * Display date for the link. Can be set to any date by the editor.
   */
  date: Date;

  /**
   * Special attention grabbing flags.
   *
   * Set to null if no flag is set as Firestore can't save undefined.
   *
   * @example 'important'
   */
  flag?: FlagType | null;

  /**
   * Who the link is from.
   *
   * @example "Kirtan das"
   */
  from?: string;

  /**
   * Firestore unique ID.
   */
  id: string;

  /**
   * Sorting order.
   *
   * Newly created Links are inserted with 0 to push them to the top of the display
   * list.
   *
   * Re-ordering the Links will properly set all of their orders.
   */
  order: number;

  /**
   * Has a viewer opened the link?
   */
  read?: boolean;

  /**
   * Title of the Link.
   */
  title: string;

  /**
   * Type of the Link.
   *
   * @example 'video'
   */
  type: LinkType;

  /**
   * When the Link was last updated.
   */
  updatedAt?: Date;

  /**
   * URL of the link.
   */
  url?: string | null;

  /**
   * Initialization vector for the encrypted title. Must change every time the title
   * is encrypted and saved.
   */
  iv: string;

  /**
   * Is the link archived?
   *
   * Only parent links can be archived.
   */
  archived: boolean;

  /**
   * Name of the user the link was archived by, if it has been.
   *
   * This is a static string and might become stale, if the users ever change their
   * display name. But we know they won't ... right?
   */
  archivedBy?: string;

  /**
   * When the Link was archived, if it has been.
   */
  archivedAt?: Date;
}
